

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/comparer-cabinets-avocats-ville-quebec.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const ContentPage6 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Comparez des avocats et des cabinets d'avocats dans la ville de Québec! - Soumissions Avocat"
        description="La ville de Québec regorge de cabinets d’avocats compétents, possédant chacun leur domaine d’expertise et leurs spécialités. C’est pourquoi vous vous devez de connaître les plus grands joueurs de la région de Québec afin de vous diriger vers le bon cabinet au moment venu. Pour bous donne un petit coup LIRE PLUS"
        image={LeadImage}>
        <h1>Comparez des avocats et des cabinets d'avocats dans la ville de Québec!</h1><p>La ville de Québec regorge de cabinets d’avocats compétents, possédant chacun leur domaine d’expertise et leurs spécialités. C’est pourquoi vous vous devez de connaître les plus grands joueurs de la région de Québec afin de vous diriger vers le bon cabinet au moment venu. Pour bous donne un petit coup de pouce, nous vous avons répertorié les principaux cabinets d’avocats œuvrant dans la vieille-capitale!</p>
<p><StaticImage alt="omparer-cabinets-avocats-ville-quebec." src="../images/comparer-cabinets-avocats-ville-quebec.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<h2>Comment choisir son avocat à Québec?</h2>
<p>Le choix d’un avocat est une démarche qui comporte un aspect très personnel et un autre très pratique. Le premier réfère surtout à la <strong>relation de confiance</strong> qui doit exister entre vous et votre avocat au moment de lui confier votre dossier. Comme celui-ci a accès à des informations confidentielles et que vous confiez le sort de votre dossier entre ses mains, la confiance se doit d’être au rendez-vous.</p>
<p>Sachez cependant que la relation entre vous et votre avocat est couverte par le <strong>secret professionnel</strong>. Ainsi, toute information confidentielle que vous révélez à votre avocat est couverte par ce secret et ce dernier n’aura non seulement pas le droit de la divulguer, mais il ne pourra pas non plus être contraint de le faire.</p>
<p>Ensuite, le choix de votre avocat se doit d’être basé sur <strong>le domaine de pratique</strong> de celui-ci. Il est faux de prétendre que les avocats pratiquent dans tous les domaines, juste parce qu’ils sont membres du barreau. La grande majorité d’entre eux se <strong>spécialisent dans un seul ou quelques domaines</strong> seulement afin d’être de fins connaisseurs de leur pratique.</p>
<p>Vient ensuite la <strong>question des honoraires</strong>! Les avocats sont des professionnels hautement qualifiés et tenus en haute estime. Pour cette raison, leurs honoraires peuvent s’avérer dispendieux dépendamment de leur niveau d’expérience. Sachez que la complexité intrinsèque d’un dossier peut également rendre son règlement onéreux.</p>
<p>Toutefois, dans un souci d’élargir les portes de la justice au plus grand nombre de gens possible, le gouvernement a mis en place un <strong>système d’aide juridique</strong>. Cela permet aux gens moins fortunés de recevoir une assistance légale gratuite ou payée en partie, à condition d’être éligible.</p>
<h2>Questions fréquentes sur les avocats à Québec</h2>
<p>Peu de gens font affaire avec des avocats dans leur vie et pour plusieurs, l’expertise légale des juristes demeure un mystère. Ayant un important réseau d’avocat dans la ville de Québec et ailleurs dans la province, nous mettons en lumière juste pour vous les principales questions adressées aux avocats!</p>
<p><StaticImage alt="Les questions à poser à un avocat à Québec avant de l’engager" src="../images/comment-choisir-avocat-questions-frequentes.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Est-ce que la poursuite en cour est la seule option?</strong></p>
<p>Loin de là! D’ailleurs, il est bien souvent dans l’intérêt des parties d’éviter que le conflit ne dégénère au point de se retrouver en cour! Pourquoi? Parce que cela implique des délais interminables, des frais judiciaires et extrajudiciaires considérables. Heureusement, des alternatives existent auprès des instances privées de règlement de différends comme la médiation et l’arbitrage.</p>
<p><strong>Comment les avocats facturent-ils leurs honoraires?
</strong>
Les avocats ont la liberté d’établir leurs honoraires comme ils le désirent, en respectant seulement des critères de proportionnalité et d’équité en fonction de la complexité du dossier. Il est donc important de demander à votre futur avocat comment il facture ses services et à combien il croit que la facture totale s’élèvera.</p>
<p><strong>Est-ce qu’il y a une différence lorsqu’on est accusé au civil ou au criminel?</strong></p>
<p>Absolument! Dans un litige civil, on constate l’opposition de deux individus qui se disputent autour d’une question de droit privé. Elles ont la liberté de régler leur différend hors cour entre elles ou encore devant le tribunal. En revanche, les accusations criminelles sont celles que le procureur de la couronne porte contre un individu lorsqu’une infraction ou un acte criminel a été commis.</p>
<p>Dans ce dernier cas, les possibilités de négociation sont minces et les conséquences d’une condamnation sont plus graves lorsque les faits sont prouvés hors de tout doute raisonnable.</p>
<p><strong>Quelle est la différence entre le notaire et l’avocat?
</strong>
Au niveau des similarités, mentionnons que le notaire et l’avocat sont tous deux des juristes ayant une formation commune. Cependant, ils possèdent chacun des compétences exclusives. Pour l’avocat, il s’agit du droit de représenter des clients en cour pour un dossier litigieux, c’est-à-dire, un dossier conflictuel entre les parties. Pour le notaire, sa compétence exclusive est celle de rédiger des actes authentiques (ex : le testament notarié) et d’exercer les pouvoirs d’officier public.</p>
<h2>Norton Rose Fulbright</h2>
<p>C’est plus de 620 employés qui travaillent pour le cabinet d’avocats Norton Rose Fulbright dans la province de Québec. Une partie de cette grande équipe offre ses services dans la région de Québec, puisque ce cabinet a pignon sur rue au cœur du Boulevard Laurier à Sainte-Foy.</p>
<p>Le type de pratique de droit que l’on retrouve chez Norton Rose Fullbright est très diversifié. On parle de droit de la propriété intellectuelle, du droit des sociétés par actions, de la concurrence et du travail. Ils sont donc des joueurs importants dans les plus hautes sphères du droit.</p>
<p>N’allez toutefois pas croire que leurs services sont réservés à l’élite! Ce cabinet offre également un programme d’accessibilité juridique et un programme pro bono, ce qui permet aux clients moins fortunés d’avoir accès à des services légaux.</p>
<h2>Lavery :</h2>
<p>La cabinet Lavery Avocats est depuis longtemps dans le « top 3 » des plus grands cabinets au Québec, employant plus de 500 avocats dans l’ensemble de la province. Pour ce qui est de leur équipe de la ville de Québec, cette dernière offre de l’assistance juridique dans une multitude de domaines qui combleront les besoins d’une clientèle diversifiés.</p>
<ul>
<li>Propriété intellectuelle</li>
<li>Litiges commerciaux</li>
<li>Capital-risque</li>
<li>Droit de la famille</li>
<li>Droit de l’éducation</li>
</ul>
<p>Ce ne sont là que quelques exemples du type de services que vous pouvez vous attendre à recevoir chez Lavery! Vous constatez donc par vous-mêmes que leur champ d’expertise est très varié et qu’ils se concentrent sur plusieurs domaines du droit! Passez les voir, leur bureau se situe sur la Grande-Allée à Québec!</p>
<h2>BCF avocats d’affaires:</h2>
<p>Le cabinet BCF se distingue de la compétition en optant pour une approche spécialisée du droit. Comme leur nom l’indique, le droit des affaires est leur domaine de prédilection et ils y excellent comme nul autres ne savent le faire.</p>
<p>Nous parlons ici d’une compétence unique en matière de fusions/acquisitions, de litiges commerciaux et corporatifs importants, de financement d’entreprise et de développement immobilier. Si de tels services s’adressent moins aux particuliers, ils sauront certainement trouver preneur auprès des entrepreneurs aguerris de tous milieux.</p>
<p><StaticImage alt="avocat-litige-poursuite-droit-quebec-cabinet" src="../images/avocat-litige-poursuite-droit-quebec-cabinet.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<h2>Cain Lamarre :</h2>
<p>Du haut de leurs bureaux de la Grande-Allée, l’équipe d’avocats de chez Cain Lamarre est plus que qualifiée pour entreprendre tous types de projets, de disputes et de démarches légales. Leur longue expérience et leur liste d’expertises pour le moins exhaustive le démontrent!</p>
<p>Leurs avocats peuvent vous aider à régler des problèmes de nature administratives ou qui relatent au droit de l’immigration, de l’immobilier, des assurances, des affaires ou du transport. Ils peuvent même vous assister pour des situations d’ordre pénales, en vous fournissant l’aide juridique appropriée!</p>
<p>En plus d’avoir de nombreux bureaux dans la plupart des plus grandes villes la province, Cain Lamarre vient en aide à tout type de clientèle. Vous serez donc accueilli les bras ouverts, quel que soit votre situation problématique!</p>
<h2>Langlois avocats :</h2>
<p>Tout comme les cabinets travaillant dans les « ligues majeures » du droit québécois, Langlois avocats offre des services de représentation juridique dans à peu près tous les plus grands domaines de pratique. Du droit administratif au droit autochtone, en passant par le droit de la santé, il n’y a à peu près par de dispute que les avocats de chez Langlois ne sauraient régler.</p>
<p>D’ailleurs, si vous souhaitez éviter vous rendre jusqu’en cours avec votre dispute, vous pourrez opter pour les services de règlement de différends. Ainsi, votre avocat fera tout en son pouvoir pour conclure une entente hors-cours avec la partie adverse. Il s’agit d’ailleurs d’une pratique en effervescence en raison de la complexité des dossiers amenés en cour.</p>
<h2>McCarthy Thétrault :</h2>
<p>McCarthy Thérault est un autre cabinet de renom dans la province de Québec. Leurs services s’adressent autant aux entreprises qu’aux particuliers. En effet, leurs avocats se spécialisent dans la gestion de dossiers de transactions commerciales, de litiges et d’actions collectives, de gestion d’entreprises et de ressources humaines.</p>
<p>Leur dévouement social se démarque parmi les cabinets de la ville de Québec! Cee cabinet consacre effectivement une partie de ses efforts à aider les gens pris dans des disputes entourant le droit du travail, les droits de la personne et la parité salariale en milieu de travail.</p>
<p>C’est donc plus qu’un simple avocat que vous trouvez chez McCarthy Thétrault; c’est un allié qui se battra pour vous jusqu’à la fin!</p>
<h2>Morency Avocats :</h2>
<p>Ce qui distingue le cabinet Morency Avocats de la compétition, c’est sa compétence en droit international. En effet, étant le seul et unique cabinet d’avocats québécois affilié à Globalaw, les juristes de chez Morency ont accès à des expertises de renommée mondiale et ont la chance de collaborer avec des avocats de cultures différentes.</p>
<p>Outre cette spécialisation, leurs avocats sont également formés en droit civil québécois et sont donc plus que capables de s’occuper de vos litiges entourant le droit du travail, du droit immobilier ou de tout autre problème légal pouvant survenir dans votre vie.</p>
<p>Ils ne sont d’ailleurs pas difficiles à trouver, puisque leur bureau se situe à l’entrée de la ville sur le Boulevard Laurier</p>
<h2>GBV Avocats</h2>
<p>Le cabinet GBV Avocats, situé, à l’entrée des ponts sur le Boulevard Laurier, concentre sa pratique dans des domaines tels que le droit du travail, le droit de l’immobilier de la construction et du droit bancaire. Qui plus est, ils ont également plusieurs avocats à leur emploi qui sont experts en règlements de différends (ententes hors cour) ainsi qu’en plaidoirie. En ce sens, vos intérêts sont protégés, quelle que soit l’issue de votre dispute légale.</p>
<p>D’ailleurs, en faisant affaire avec eux, vous serez automatiquement redirigé vers les avocats qui se spécialisent dans le type de dossier que vous leur apportez. Si GBV n’a que deux bureaux dans toute la province, leur taille n’enlève rien à leur compétence. Plusieurs de leurs avocats se sont mérité des distinctions de « Best Lawyer in Canada » ou encore « Lawyer of the year », et ce, à plusieurs reprises. Vous êtes entre bonnes mains chez GBV, cela ne fait nul doute!</p>
<p><StaticImage alt="avocat-droit-erreur-medicale-litige-differend" src="../images/avocat-droit-erreur-medicale-litige-differend.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<h2>Cabinet Guy Bertrand Inc.</h2>
<p>Une poignée d’avocats s’affaire à régler des problèmes de toutes sortes au cabinet Guy Bertrand Inc. Localisé sur la Grande-Allée Ouest, ce cabinet est apte à régler des disputes dans tous les domaines de droit, mais surtout dans des disputes tournant autour de la responsabilité civile, de l’erreur médicale et du congédiement injustifié.</p>
<p>Il s’agit d’ailleurs de types de litiges qui exigent des expertises très raffinées, car ce les dossiers sont toujours très complexes et extrêmement nuancés d’un côté comme de l’autre. Pour les litiges entourant les erreurs médicales, vous devrez trouver de nombreux experts du milieu médical pour appuyer votre cause avec toutes les expertises pertinentes. Ce n’est pas là un travail que n’importe quel avocat fraîchement diplômé du Barreau peut accomplir. Heureusement, les avocats du cabinet Guy Bertrand en ont vu d’autres et seront plus que capables de vous épauler dans un tel dossier!</p>
<h2>Bouchard Pagé Tremblay</h2>
<p>Une douzaine d’avocats de tout type de pratique œuvrent au sein du cabinet Bouchard Pagé Tremblay. Tout presque tous les cabinets d’une certaine importance dans la Ville de Québec, leurs avocats sont experts dans les domaines tels que le travail, les litiges, l’immobilier et les affaires. Cependant, un des secteurs du droit qui les sépare du lot est leur concentration sur le droit de la propriété.</p>
<p>Ce genre de disputes tourne autour des droits entourant la propriété foncière, tels les droits réels comme les servitudes, emphytéoses, usufruits, demande de bornage, prescription acquisitive, etc. Si certaines de ces situations sont peu communes, d’autres surviennent plus fréquemment. C’est le cas notamment des fameuses chicanes de clôture! De plus, si une situation de trouble de voisinage ou de dispute entre copropriétaires, les avocats de chez Bouchard Pagé Tremblay s’avèreraient des alliés incomparables!</p>
<h2>Joli-Cœur Lacasse</h2>
<p>Joli-Cœur Lacasse divise son offre de services en 3 grandes catégories de projets : internationaux, personnels et d’entreprise. Au niveau international ce cabinet est en mesure d’assister pour tous les enjeux légaux qui tournent autour du droit de l’immigration, du règlement de différends à l’échelle internationale et des partenariats avec des entreprises étrangères. Plusieurs alliés de diverses organisations internationales épaulent Joli-Cœur Lacasse dans leur pratique et donc, par la bande, servent vos intérêts également.</p>
<p>Du côté personnel et entrepreneurial, leurs avocats sont formés dans tous les domaines classiques du droit, tels que ceux de la famille, des affaires et peuvent même aider votre entreprise individuelle à s’incorporer et à rédiger une convention d’actionnaires. Bref, Joli-Cœur Lacasse est aussi polyvalent que compétent, ce qui fait d’eux une référence en droit québécois!</p>
<h2>Tremblay Bois Mignault Lemay Avocats</h2>
<p>Ce cabinet se définit par une pratique concentrée en 4 champs d’expertise : le droit municipal, le droit des affaires, le droit des assurances et les dossiers d’erreur médicale. Ce sont des là des spécialités qui sortent de l’ordinaire des pratiques en grand cabinet, mais qui sont certaines de trouver preneur auprès de tous les types de clientèles. La pratique du droit municipal consiste à fournir une assistance pour tous les dossiers légaux qui tournent autour de l’administration d’une ville, tel que l’émission d’appels d’offre, de soumissions, d’octroi de contrats, de zonage, etc. Il s’agit d’une spécialité au même titre que le droit médical.</p>
<p>Concernant ce dernier, l’équipe de Tremblay Bois Mignault Lemay est une référence dans le milieu en ce qui concerne les dossiers d’erreur médicale. Comme nous l’avons mentionné précédemment, de tels dossiers sont parmi les plus complexes en droit, en plus d’avoir un côté humain que peu de causes légales arrivent à égaler. Vous pouvez toutefois être certain d’avoir trouvé des avocats compétents dans ce cabinet!</p>
<h2>Sophie Lafleur Avocate</h2>
<p>Une dispute impliquant un point de droit de la famille est certaine de créer une situation délicate. Tous les partis impliqués ne cherchent qu’une chose : le meilleur intérêt de l’enfant. Cependant, tous ne s’entendront pas sur ce qui définit le meilleur intérêt de cet enfant. Que vous soyez pris dans une cause de divorce, de demande de garde partagée, d’adoption ou de déchéance de l’autorité parentale, Maître Sophie Lafleur saura vous offrir une expertise humaine au plus haut seuil de compétence possible en droit.</p>
<p>Cette avocate réputée en droit de la famille peut également épauler quelconque client qui se trouve dans une situation nécessitant l’intervention du Département de la protection de la jeunesse (DPJ).</p>
<p><StaticImage alt="services-avocat-droit-famille-enfance-quebec" src="../images/services-avocat-droit-famille-enfance-quebec.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<h2>Lajoie &amp; Pearson Avocats</h2>
<p>Au même titre que Maître Lafleur, le cabinet d’avocats Lajoie &amp; Pearson est également spécialisé en droit de la famille, de la jeunesse et de l’enfance. Les mineurs ont beaucoup plus de droits que certains peuvent le croire, et ce, peu importe leur âge. Vous seriez surpris d’en apprendre davantage à ce sujet et c’est le rôle des avocats de Lajoie &amp; Pearson de vous guider dans ce secteur de droit.</p>
<p>Qu’il soit question de pension alimentaire, de garde, de divorce ou de mariage, leurs juristes vous aideront au meilleur de leurs connaissances. Ce ne sont pas les connaissances légales qui manquent chez Lajoie &amp; Pearson.</p>
<h2>BB Immigration</h2>
<p>L’immigration est en hausse au Canada, et ce, depuis plusieurs années. Cela est d’autant plus vrai au Québec! Une telle augmentation d’arrivants engendre inévitablement un besoin grandissant pour des conseils légaux sur le droit de l’immigration. Heureusement pour ces nouveaux arrivants que les avocats de BB Immigration se spécialisent dans toutes les démarches légales reliées à l’obtention d’une citoyenneté canadienne ou d’une contestation d’expulsion du pays.</p>
<p>Les services suivants font partie du quotidien des avocats de chez BB Immigration :</p>
<ul>
<li><strong>Demande de visa pour travailler au Canada</strong></li>
<li><strong>Dossiers de perte de statut de résident permanent</strong></li>
<li><strong>Demande de visa d’étude</strong></li>
<li><strong>Demandes de citoyenneté canadienne</strong></li>
</ul>
<p>Que vous soyez pris dans une situation problématique ou que vous tentiez simplement d’obtenir un statut de citoyen en bonne et due forme, BB Immigration et ses avocats vous aideront sans problème!</p>
<h2>Ménard Millard Caux Avocats</h2>
<p>Ce qui distingue Ménard Millard Caux de la concurrence, c’est leur expertise dans les domaines du droit du travail et du droit social. En matière de droit du travail, ils sont en mesure de vous assister dans tous les types de causes. En effet, qu’il s’agisse d’une plainte effectuée en vertu de la Loi sur les normes du travail, du Code du travail ou du Code civil du Québec, un de leurs avocats saura user de sa spécialité pour vous tirer d’affaire.</p>
<p>Vous avez été victime d’un congédiement abusif ou on a empiété sur vos droits fondamentaux prévus par la Charte dans votre milieu de travail? Encore une fois, ce cabinet emploie les avocats qu’il vous faut!</p>
<p>Une pratique particulière caractérise également le cabinet Ménard Millard Caux, soit celui du droit social. Il consiste à entreprendre des recours en matière d’assurance emploi, d’invalidité, de dossiers impliquant la CNESST, la SAAQ et d’autres organismes gouvernementaux ou paragouvernementaux.</p>
<h2>Lévesque Lavoie Avocats</h2>
<p>Chez Lévesque Lavoie, les avocats empruntent une approche simplifiée du droit en rendant leurs services accessibles aux entreprises d’ici. Ils sont surtout présents dans le monde des affaires et droit corporatif, ce qui leur permet de pratiquer dans des domaines tels que la faillite, la restructuration d’entreprise, la mise en place de fiducies et la planification de successions.</p>
<p>Pour ce qui est des services adressés spécialement aux entreprises, les avocats de chez Lévesque Lavoie sont en mesure de procéder à l’incorporation de votre entreprise, au règlement d’une dispute entre actionnaires et à la rédaction d’une convention régissant les interactions entre ces mêmes personnes.</p>
<p>Pour ce qui est des opérations d’envergure du type « fusions et acquisitions », vous aurez accès aux services d’un avocat pour effectuer les vérifications diligentes nécessaires pour conclure la transaction. Au final, vous aurez veillé aux meilleurs intérêts de votre entreprise en retenant les services d’un avocat de ce cabinet!</p>
<h2>Aide juridique de Québec</h2>
<p>Le service d’aide juridique-offert par le Centre communautaire juridique de Québec est mis à la disposition est gens à revenu modique ayant besoin d’assistance légale. Il s’agit d’un service mis à la disposition des citoyens moins fortunés dans plusieurs grandes villes de la province, dont Québec!</p>
<p>D’ailleurs, ce sont presque tous les domaines de droits qui sont couverts par les avocats travaillant pour l’aide juridique, soit : le droit criminel et pénal, le droit de la jeunesse, le droit social, le droit de la famille, le droit de l’immigration et plusieurs domaines où une offre est pertinente pour les gens aux moyens financiers limités.</p>
<p>Cependant, pour avoir droit à un avocat gratuit fourni par le service d’aide juridique, vous devez avoir un revenu inférieur à un certain seuil. Les barèmes commencent à 21 840$ en revenus annuels pour une personne seule et se terminent à 35 800 pour des conjoints ayant 2 enfants ou plus!</p>
<p>De plus, dans le calcul de votre admissibilité, le centre communautaire juridique de Québec prendra aussi en compte votre revenu, votre situation familiale et civile (enfants, marié, célibataire, etc.), la valeur de vos biens et la liste de vos actifs par rapport à vos passifs.</p>
<p>Il n’y a aucune raison d’être gêné d’utiliser l’aide juridique! L’accès à la justice est un problème répandu dans la société et si vous pouvez être épargné de cette inaccessibilité, profitez-en sans hésiter!</p>
<h2>Laissez-nous vous référer aux meilleurs avocats sans plus tarder!</h2>
<p><StaticImage alt="engager-avocat-services-juridiques-ville-quebec" src="../images/engager-avocat-services-juridiques-ville-quebec.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Même si les cabinets que nous vous avons présentés ci-haut sont réellement des valeurs sures dans le monde du droit à Québec, rien ne vous empêche de pousser vos recherches un peu plus loin!</p>
<p><strong>Si tel est votre désir, nous sommes certainement en mesure de vous aider!</strong></p>
<p>Pour comparer l’offre de service des différents avocats de la région de Québec, vous n’avez qu’à remplir le formulaire en bas de page pour recevoir 3 soumissions gratuites!</p>
    </SeoPage>
)
export default ContentPage6
  